import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { users as usersData } from './data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class UserManagementMockApi {
    private _users: any = usersData;

    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    registerHandlers(): void {
        this._fuseMockApiService.onGet('api/users').reply(() => {
            return [200, cloneDeep(this._users)];
        });

        this._fuseMockApiService.onDelete('api/users').reply(({ request }) => {
            // Get the id
            const id = request.params.get('id');
            // Find the user and delete it
            const index = usersData.findIndex((item) => item.id === +id);
            usersData.splice(index, 1);

            return [200, true];
        });
    }
}
